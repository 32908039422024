<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "diaoyu",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到钓鱼页",
                            "#按图1说明参数:",
                            "1.钓鱼支持大部分鱼群",
                            "2.支持'白肌银鱼','肥硕鲳鱼'两种鱼的自动分解",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#角色移动到鱼群前,确保鱼群和名字可以显示(图1), 开始任务",
                            '#如果鱼群不能识别,移动视角或者角色位置重试',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
